import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, switchMap, take } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';

import { AuthService } from '@wellro/auth/data-access';
import { ServerResponse } from '@wellro/auxilary-models';
import { LoggerService } from '@wellro/utils';
import { FileUploaderService } from './file-uploader.service';
import { VoiceClipAvatar } from '@wellro/models';

@Injectable({
  providedIn: 'root',
})
export class VoiceClipService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private fileUploaderService: FileUploaderService,
    private af: AngularFirestore,
    private logger: LoggerService
  ) {}

  createVoiceClip(
    voiceClipName: string,
    sourceFile: File,
    audioDuration: number
  ) {
    return this.authService.getUserStream().pipe(
      switchMap(({ userId }) =>
        this.fileUploaderService
          .uploadFile(
            sourceFile,
            `Users/${userId}/VoiceClips`,
            sourceFile.name,
            {
              audioDuration,
            }
          )
          .pipe(map((voiceClipPublicUrl) => ({ userId, voiceClipPublicUrl })))
      ),
      switchMap(({ voiceClipPublicUrl, userId }) =>
        this.http.post<ServerResponse<VoiceClipAvatar>>(
          `/api/doctors/${userId}/voice-clips`,
          {
            voiceClipName,
            voiceClipFileName: sourceFile.name,
            voiceClipPublicUrl,
          }
        )
      ),
      take(1),
      map((response) => {
        if (response.success) {
          return response.data;
        }

        this.logger.error(`Couldn't create voice clip`, response);

        throw new Error(response.message);
      })
    );
  }

  getAllVoiceClips() {
    return this.authService.getUserStream().pipe(
      switchMap(({ userId }) => {
        return this.http.get<ServerResponse<VoiceClipAvatar[]>>(
          `/api/doctors/${userId}/voice-clips`
        );
      }),
      take(1),
      map((response) => {
        if (response.success) {
          return response.data;
        }

        this.logger.error(`Couldn't get all voice clips`, response);

        throw new Error(response.message);
      })
    );
  }

  updateVoiceClip(voiceClipId: string, voiceClipName: string) {
    return this.authService.getUserStream().pipe(
      switchMap(({ userId }) => {
        return this.http.patch<ServerResponse<VoiceClipAvatar>>(
          `/api/doctors/${userId}/voice-clips/${voiceClipId}`,
          { voiceClipName }
        );
      }),
      take(1),
      map((response) => {
        if (response.success) {
          return response.data;
        }

        this.logger.error(`Couldn't update voice clips`, response);

        throw new Error(response.message);
      })
    );
  }

  deleteVoiceClip(voiceClipId: string) {
    return this.authService.getUserStream().pipe(
      switchMap(({ userId }) => {
        return this.http.delete<ServerResponse<null>>(
          `/api/doctors/${userId}/voice-clips/${voiceClipId}`
        );
      }),
      take(1),
      map((response) => {
        if (response.success) {
          return response.data;
        }

        this.logger.error(`Couldn't delete voice clips`, response);

        throw new Error(response.message);
      })
    );
  }
}
