import { Injectable } from '@angular/core';

@Injectable()
export class ColorGeneratorService {
  colorFromText(text: string): string {
    const num = text
      .split('')
      .map((n) => n.charCodeAt(0))
      .reduce((a, b) => a + b);
    return `#${Math.floor((num * 0xffffff) / 250).toString(16)}`;
  }

  isColorDark(text: string): boolean {
    const num = text
      .split('')
      .map((n) => n.charCodeAt(0))
      .reduce((a, b) => a + b);
    const color = Math.floor((num * 0xffffff) / 250)
      .toString(16)
      .padStart(6, '0');
    const rgb = parseInt(color, 16);
    const luma =
      0.2126 * ((rgb >> 16) & 0xff) +
      0.7152 * ((rgb >> 8) & 0xff) +
      0.0722 * ((rgb >> 0) & 0xff);
    return luma < 190;
  }
}
