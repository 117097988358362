import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { AuthService } from '@wellro/auth/data-access';
import { PaginatedContent, ServerResponse } from '@wellro/auxilary-models';
import {
  Consultation,
  ConsultationDocument,
  ConsultationMessageType,
  ConsultationStatus,
  CustomerDocument,
  DoctorMessage,
  DoctorMessageDocument,
  PatientMessage,
  PatientMessageDocument,
} from '@wellro/models';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConsultationsService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private af: AngularFirestore,
    private afStorage: AngularFireStorage
  ) {}

  getConsultationsByStatus(status: ConsultationStatus) {
    return this.authService.getUserStream().pipe(
      switchMap((user) =>
        this.http.get<ServerResponse<Consultation[]>>(
          `/api/doctors/${user.userId}/consultations/${status}`
        )
      ),
      map((response) => {
        if (response.success) {
          return response.data;
        }

        return [];
      })
    );
  }

  getPaginatedClosedConsultations(
    customerId: string,
    cursor?: string,
    limit: number = 1
  ) {
    return this.authService.getUserStream().pipe(
      switchMap((user) => {
        const params = new HttpParams()
          .set('limit', limit.toString())
          .set('cursor', cursor);
        return this.http.get<
          ServerResponse<
            PaginatedContent<
              {
                consultation: Consultation;
                messages: (PatientMessage | DoctorMessage)[];
              },
              string
            >
          >
        >(`/api/doctors/${user.userId}/consultations-history/${customerId}`, {
          params,
        });
      }),
      map((response) => {
        if (response.success) {
          return response.data;
        }

        return { data: [], cursor: '' };
      })
    );
  }

  acceptConsultation(consultationId: string) {
    return this.http
      .post<ServerResponse<any>>(
        `/api/consultations/${consultationId}/accept`,
        {}
      )
      .pipe(
        map((response) => {
          if (response.success) {
            return response.message;
          }

          throw new Error(response.message);
        })
      );
  }

  updateConsultationAsOngoing(consultationId: string) {
    return this.http
      .post<ServerResponse<any>>(
        `/api/consultations/${consultationId}/set-ongoing`,
        {}
      )
      .pipe(
        map((response) => {
          if (response.success) {
            return response.message;
          }

          throw new Error(response.message);
        })
      );
  }

  rejectConsultation(consultationId: string) {
    return this.http
      .post<ServerResponse<any>>(
        `/api/consultations/${consultationId}/reject`,
        {}
      )
      .pipe(
        map((response) => {
          if (response.success) {
            return response.message;
          }

          throw new Error(response.message);
        })
      );
  }

  getConsultation(consultationId: string) {
    return this.http
      .get<
        ServerResponse<{
          consultation: Consultation;
          messages: PatientMessage[] | DoctorMessage[];
        }>
      >(`/api/consultations/${consultationId}/messages`)
      .pipe(
        map((response) => {
          if (response.success) {
            return response.data;
          }

          return null;
        })
      );
  }

  declineConsultation(consultationId: string) {
    return this.http
      .post<ServerResponse<any>>(
        `/api/consultations/${consultationId}/decline`,
        {}
      )
      .pipe(
        map((response) => {
          if (response.success) {
            return response.message;
          }

          throw new Error(response.message);
        })
      );
  }

  getPatientDetails(patientId: string) {
    const customerDocumentRef = this.af
      .doc<CustomerDocument>(`Users/${patientId}`)
      .get();
    return customerDocumentRef.pipe(
      map((snapshot) => {
        if (!snapshot.exists) {
          throw new Error('Could not get patient details');
        }
        return snapshot.data();
      })
    );
  }

  waitForReply(consultationId: string) {
    return this.http
      .post<ServerResponse<any>>(
        `/api/consultations/${consultationId}/wait-for-reply`,
        {}
      )
      .pipe(
        map((response) => {
          if (response.success) {
            return response.message;
          }

          throw new Error(response.message);
        })
      );
  }

  completeConsultation(consultationId: string) {
    return this.http
      .post<ServerResponse<any>>(
        `/api/consultations/${consultationId}/complete`,
        {}
      )
      .pipe(
        map((response) => {
          if (response.success) {
            return response.message;
          }

          throw new Error(response.message);
        })
      );
  }

  watchConsultationMessages(consultationId: string) {
    return this.af
      .collection<DoctorMessageDocument | PatientMessageDocument>(
        `Consultations/${consultationId}/ConsultationMessages`
      )
      .valueChanges();
  }

  getPatientQuestionnaire(consultationId: string) {
    return this.af
      .collection<PatientMessageDocument>(
        `Consultations/${consultationId}/ConsultationMessages`,
        (ref) =>
          ref
            .where('type', '==', ConsultationMessageType.RichText)
            .orderBy('createdAt', 'desc')
            .limit(1)
      )
      .get()
      .pipe(map((result) => result.docs.map((e) => e.data())[0]));
  }

  addDoctorMessageToConsultation(
    consultationId: string,
    title: string,
    messageText: string,
    imageUrls: string[],
    voiceNoteUrl: string,
    prescriptionUrl: string
  ) {
    return this.http
      .post<ServerResponse<ServerResponse<string>>>(
        `/api/consultations/${consultationId}/messages/send`,
        {
          title,
          messageText,
          images: imageUrls,
          voiceNote: voiceNoteUrl,
          prescriptionUrl,
        }
      )
      .pipe(
        map((response) => {
          if (response.success) {
            return response.message;
          }

          throw new Error(response.message);
        })
      );
  }

  setDoctorAvailability(doctorId: string, accept: boolean) {
    return this.http
      .put<ServerResponse<string>>(`/api/doctors/${doctorId}/availability`, {
        accept,
      })
      .pipe(
        map((response) => {
          if (response.success) {
            return response.message;
          }
          throw new Error(response.message);
        })
      );
  }

  getDoctorAvailability(doctorId: string) {
    return this.http
      .get<ServerResponse<{ isAcceptingConsultations: boolean }>>(
        `/api/doctors/${doctorId}/availability`
      )
      .pipe(
        map((response) => {
          if (response.success) {
            return response.data.isAcceptingConsultations;
          }

          throw new Error(response.message);
        })
      );
  }

  getConsultationsStream() {
    return this.authService.getUserStream().pipe(
      switchMap((user) => {
        return this.af
          .collection<ConsultationDocument>('Consultations', (ref) =>
            ref.where('doctorId', '==', user.userId)
          )
          .valueChanges();
      })
    );
  }

  getConsultationStream(consultationId: string) {
    return this.af
      .doc<ConsultationDocument>(`Consultations/${consultationId}`)
      .valueChanges();
  }

  getPastConsultations() {
    return this.authService.getUserStream().pipe(
      switchMap((user) =>
        this.af
          .collection<ConsultationDocument>('Consultations', (ref) =>
            ref
              .where('doctorId', '==', user.userId)
              .where('status', '==', ConsultationStatus.Closed)
          )
          .get()
      ),
      map((snapshot) => {
        if (snapshot.empty) {
          return [];
        }
        return snapshot.docs.map((docSnapshot) => docSnapshot.data());
      })
    );
  }
}
