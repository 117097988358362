import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '@wellro/auth/data-access';
import { ServerResponse } from '@wellro/auxilary-models';
import { LoggerService } from '@wellro/utils';
import firebase from 'firebase/app';
import { from } from 'rxjs';
import { map, mapTo, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthHelperService {
  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private authService: AuthService,
    private afa: AngularFireAuth
  ) {}

  signUpWithEmail(email: string, password: string) {
    return from(this.afa.createUserWithEmailAndPassword(email, password));
  }

  signInWithGoogle() {
    const authProvider = new firebase.auth.GoogleAuthProvider();
    return from(this.afa.signInWithPopup(authProvider));
  }

  activateAccount(code: string) {
    return this.authService.getUserStream().pipe(
      take(1),
      switchMap((user) => {
        const userId = user.userId;

        return this.http
          .post<ServerResponse<string>>('/api/doctors/activate', {
            userId,
            code,
          })
          .pipe(
            switchMap((response) => {
              if (response.success) {
                return this.authService
                  .refreshSession()
                  .pipe(mapTo(response.data));
              }
              this.logger.error('Could not activate the doctor', { userId });
              throw Error(response.message);
            })
          );
      })
    );
  }

  sendOtpCode(mobileNumber: string) {
    return this.authService.getUserStream().pipe(
      take(1),
      switchMap((user) => {
        const userId = user.userId;

        return this.http
          .post<ServerResponse<{ userId: string; enableResendAt: number }>>(
            '/api/otp/create',
            {
              id: userId,
              phoneNumber: mobileNumber,
            }
          )
          .pipe(
            map((response) => {
              if (response.success) {
                return response.data;
              }
              this.logger.error('Could not send OTP to mobile number', {
                mobileNumber,
              });
              throw Error(response.message);
            })
          );
      })
    );
  }

  verifyOTPCode(code: string, phoneNumber: string) {
    return this.authService.getUserStream().pipe(
      take(1),
      switchMap(({ userId }) =>
        this.http
          .post<ServerResponse<string>>('/api/otp/verify', {
            id: userId,
            code,
            phoneNumber,
          })
          .pipe(
            switchMap((response) => {
              if (response.success) {
                return this.authService
                  .refreshSession()
                  .pipe(mapTo(response.data));
              }
              this.logger.error('Mobile number verification failed', { code });
              throw new Error(response.message);
            })
          )
      )
    );
  }
}
